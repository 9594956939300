import axios from 'axios';
import {baseGetAPIRequest, basePostAPIRequest, getRequestInfo} from '../common/utils';
import history from "../../history";

import {
    BALANCE_FLOWS_REPORT_URL,
    BILLING_PAYMENT_URL,
    CANCEL_SALE_URL,
    CASH_REPORT_URL,
    CATEGORIES_URL,
    CLOSE_SHIFT_URL,
    CREDIT_NOTES_REPORT_URL,
    DOWNLOAD_RECEIPT_PDF,
    GET_BALANCE_STATUS_URL,
    GET_CLOSED_BALANCE_URL,
    GET_CONSUMPTIONS_HISTORY_URL,
    GET_CONSUMPTIONS_URL,
    GET_EXISTING_BALANCE_URL,
    GET_GUESTS_URL,
    GET_PAYMENT_TYPES_URL,
    GET_PAYMENTS_URL,
    GET_SALES_BY_BALANCE_URL,
    GET_SALES_HISTORY_URL,
    GUEST_FLOWS_REPORT_URL,
    GUEST_URL,
    INVOICES_REPORT_URL,
    OPEN_BALANCE_URL,
    POS_PAYMENT_URL,
    POST_PAYMENT_URL,
    PRODUCT_PRICES_FILE,
    PRODUCTS_URL,
    RANKING_REPORT_URL,
    REPORT_CASH_BALANCE,
    RESERVATIONS_ALL_TIME,
    RESERVATIONS_ALL_TIME_DETAILED_RATES,
    ROOMS_URL,
    SALES_REPORT_URL,
    TICKETS_REPORT_URL
} from './apiUrlsConstants';
import {refreshToken} from "../common/apiCalls";

export async function retryRequest(url, retries = 2) {
    try {
        return await baseGetAPIRequest(url);
    } catch (err) {
        if (err.response.status === 401 && retries) {
            try {
                const token = await refreshToken();
                localStorage.setItem('accessToken', token.data.access);
                return retryRequest(url, retries - 1)
            } catch (e) {
                history.push("/login");
            }
        } else {
            throw new Error(err) // when FAIL8
        }
    }
}


export function requestProducts() {
    return baseGetAPIRequest(PRODUCTS_URL)
}

export function requestCategories() {
    return baseGetAPIRequest(CATEGORIES_URL)
}

export function requestRooms() {
    return baseGetAPIRequest(ROOMS_URL)
}

export function requestGuest() {
    return baseGetAPIRequest(GUEST_URL)
}

export function postRequestBillingPayment(data) {
    const countryCode = localStorage.getItem('country')
    if(countryCode === 'CL'){
        return basePostAPIRequest(BILLING_PAYMENT_URL, data,'blob')
    }
    return basePostAPIRequest(BILLING_PAYMENT_URL, data)
}

export function postRequestPOSPayment(data) {
    const countryCode = localStorage.getItem('country')
    if(countryCode === 'CL'){
        return basePostAPIRequest(POS_PAYMENT_URL, data,'blob')
    }
    return basePostAPIRequest(POS_PAYMENT_URL, data)
}

export function requestBalanceFlowsReport() {
    return baseGetAPIRequest(BALANCE_FLOWS_REPORT_URL)
}

export function requestGuestFlowsReport(filtering) {
    const URL = GUEST_FLOWS_REPORT_URL + `?filtering=${filtering}`;
    return baseGetAPIRequest(URL)
}

export function requestSalesReport(startDate, endDate) {
    const URL = SALES_REPORT_URL + `?start-date=${startDate}&end-date=${endDate}`;
    return baseGetAPIRequest(URL)
}

export function requestXlsxSalesReport(startDate, endDate) {
    const params = {
        "start-date": startDate,
        "end-date": endDate
    }
    const token = localStorage.getItem('accessToken')
    const {apiUrl, headers} = getRequestInfo(SALES_REPORT_URL)
    headers['Authorization'] = `Bearer ${token}`;
    return axios.get(apiUrl, {responseType: 'arraybuffer', headers: headers, params: params})
}

export function requestTicketsReport(startDate, endDate) {
    const URL = TICKETS_REPORT_URL + `?start-date=${startDate}&end-date=${endDate}`;
    return baseGetAPIRequest(URL)
}

export function requestXlsxTicketsReport(startDate, endDate) {
    const params = {
        "start-date": startDate,
        "end-date": endDate
    }
    const token = localStorage.getItem('accessToken')
    const {apiUrl, headers} = getRequestInfo(TICKETS_REPORT_URL)
    headers['Authorization'] = `Bearer ${token}`;
    return axios.get(apiUrl, {responseType: 'arraybuffer', headers: headers, params: params})
}

export function requestInvoicesReport(startDate, endDate) {
    const URL = INVOICES_REPORT_URL + `?start-date=${startDate}&end-date=${endDate}`;
    return baseGetAPIRequest(URL)
}

export function requestXlsxInvoicesReport(startDate, endDate) {
    const params = {
        "start-date": startDate,
        "end-date": endDate
    }
    const token = localStorage.getItem('accessToken')
    const {apiUrl, headers} = getRequestInfo(INVOICES_REPORT_URL)
    headers['Authorization'] = `Bearer ${token}`;
    return axios.get(apiUrl, {responseType: 'arraybuffer', headers: headers, params: params})
}

export function requestCreditNotesReport(startDate, endDate) {
    const URL = CREDIT_NOTES_REPORT_URL + `?start-date=${startDate}&end-date=${endDate}`;
    return baseGetAPIRequest(URL)
}

export function requestXlsxCreditNotesReport(startDate, endDate) {
    const params = {
        "start-date": startDate,
        "end-date": endDate
    }
    const token = localStorage.getItem('accessToken')
    const {apiUrl, headers} = getRequestInfo(CREDIT_NOTES_REPORT_URL)
    headers['Authorization'] = `Bearer ${token}`;
    return axios.get(apiUrl, {responseType: 'arraybuffer', headers: headers, params: params})
}

export function requestRankingReport(startDate, endDate, origin) {
    const URL = RANKING_REPORT_URL + `?start-date=${startDate}&end-date=${endDate}&origin=${origin}`;
    return baseGetAPIRequest(URL)
}

export function requestXlsxRankingReport(startDate, endDate, origin) {
    const params = {
        "start-date": startDate,
        "end-date": endDate,
        "origin": origin
    }
    const token = localStorage.getItem('accessToken')
    const {apiUrl, headers} = getRequestInfo(RANKING_REPORT_URL)
    headers['Authorization'] = `Bearer ${token}`;
    return axios.get(apiUrl, {responseType: 'arraybuffer', headers: headers, params: params})
}

export function requestSalesByBalance() {
    return baseGetAPIRequest(GET_SALES_BY_BALANCE_URL)
}

export function requestSalesHistory() {
    return baseGetAPIRequest(GET_SALES_HISTORY_URL)
}

export function requestConsumptionsHistory() {
    return baseGetAPIRequest(GET_CONSUMPTIONS_HISTORY_URL)
}

export function requestBalanceStatus() {
    return baseGetAPIRequest(GET_BALANCE_STATUS_URL)
}

export function requestCloseShift(closing_shift_information) {
    const token = localStorage.getItem('accessToken')
    const {apiUrl, headers} = getRequestInfo(CLOSE_SHIFT_URL)
    headers['Authorization'] = `Bearer ${token}`;
    return axios.post(apiUrl, closing_shift_information, {responseType: 'arraybuffer', headers: headers})
}

export function requestReportCashBalance(balance_id) {
    const token = localStorage.getItem('accessToken')
    const {apiUrl, headers} = getRequestInfo(REPORT_CASH_BALANCE)
    headers['Authorization'] = `Bearer ${token}`;
    return axios.get(apiUrl, {responseType: 'arraybuffer', headers: headers, params: balance_id})
}

export function requestReservationsAllTime() {
    const token = localStorage.getItem('accessToken');
    const {apiUrl, headers} = getRequestInfo(RESERVATIONS_ALL_TIME)
    headers['Authorization'] = `Bearer ${token}`;
    return axios.get(apiUrl, {responseType: 'arraybuffer', headers: headers})
}

export function getProductPricesFile() {
    const token = localStorage.getItem('accessToken');
    const {apiUrl, headers} = getRequestInfo(PRODUCT_PRICES_FILE)
    headers['Authorization'] = `Bearer ${token}`;
    return axios.get(apiUrl, {responseType: 'arraybuffer', headers: headers})
}

export function requestReservationsAllTimeDetailedRates() {
    const token = localStorage.getItem('accessToken');
    const {apiUrl, headers} = getRequestInfo(RESERVATIONS_ALL_TIME_DETAILED_RATES)
    headers['Authorization'] = `Bearer ${token}`;
    return axios.get(apiUrl, {responseType: 'arraybuffer', headers: headers})
}

export function requestOpenBalance(currencyData) {
    const token = localStorage.getItem('JWT-access');
    const {apiUrl, headers} = getRequestInfo(OPEN_BALANCE_URL);
    headers['Authorization'] = 'Bearer ' + token;
    return axios.post(apiUrl, currencyData, headers)
}

export function requestExistingBalance() {
    return baseGetAPIRequest(GET_EXISTING_BALANCE_URL)
}

export function requestClosedBalance() {
    return baseGetAPIRequest(GET_CLOSED_BALANCE_URL)
}


export function requestCashBalance(date) {
    const URL = CASH_REPORT_URL + `?date=${date}`;
    return baseGetAPIRequest(URL)
}

export function requestFailedSales() {
    return baseGetAPIRequest('failedSales')
}


export function requestGuests() {
    const token = localStorage.getItem('JWT-access');
    const {apiUrl, headers} = getRequestInfo(GET_GUESTS_URL);
    headers['Authorization'] = 'Bearer ' + token;
    return axios.get(apiUrl, headers)
}

export function requestConsumptions() {
    const token = localStorage.getItem('JWT-access');
    const {apiUrl, headers} = getRequestInfo(GET_CONSUMPTIONS_URL);
    headers['Authorization'] = 'Bearer ' + token;
    return axios.get(apiUrl, headers)
}

export function requestPaymentTypes() {
    const token = localStorage.getItem('JWT-access');
    const {apiUrl, headers} = getRequestInfo(GET_PAYMENT_TYPES_URL);
    headers['Authorization'] = 'Bearer ' + token;
    return axios.get(apiUrl, headers)
}


export function requestPayments() {
    const token = localStorage.getItem('JWT-access');
    const {apiUrl, headers} = getRequestInfo(GET_PAYMENTS_URL);
    headers['Authorization'] = 'Bearer ' + token;
    return axios.get(apiUrl, headers)
}

export function requestProcessPayment() {
    const token = localStorage.getItem('JWT-access');
    const {apiUrl, headers} = getRequestInfo(POST_PAYMENT_URL);
    headers['Authorization'] = 'Bearer ' + token;
    return axios.get(apiUrl, headers)
}


// CREDIT NOTE
export function postRequestCancelSale(data) {
    return basePostAPIRequest(CANCEL_SALE_URL, data,'blob')
}


//PDF DOWNLOADS FOR DOCUMENTS
export function requestDownloadDocumentsPdf(correlative) {
    const URL = DOWNLOAD_RECEIPT_PDF + `?correlatives=${correlative}`;
    return baseGetAPIRequest(URL)
}