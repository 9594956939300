import * as fromSelected from "../reducers/selectedConsumption.reducer"
import * as fromConsumption from "../reducers/guestConsumption.reducer"
import {ACCO_CODE} from "../constants"

const guestsSelected = state => fromConsumption.selectedGuest(state.guestConsumption);
const getConsumption = (state, id) => fromConsumption.getConsumption(state.guestConsumption, id);
const guestOutletConsumption = (state, guestId) => fromConsumption.consumptionByGuest(state.guestConsumption, guestId).outlets;

// const getStay = (state, id) => fromSelected.getStayQuantity(state.selectedConsumption, id);
const isCompanyPayment = state => state.paymentState.isCompany;
export const getConsumptionItems = (state, concept) => fromSelected.getConceptItems(state.selectedConsumption, concept);
const consumptionDiscount = (state, concept) => fromSelected.getConceptDiscount(state.selectedConsumption, concept);

function calculateTotals(items) {
    if (!items) return 0;
    return Object.values(items).filter(item => item.quantity && !item.isCancelled).reduce((accu, item) => {
        return accu + (item.quantity * item.price.total)
    }, 0)
}

function calculateSubTotals(items) {
    if (!items) return 0;
    return Object.values(items).filter(item => item.quantity && !item.isCancelled).reduce((accu, item) => {
        return accu + (item.quantity * item.price.subtotal)
    }, 0)
}

function calculateTaxes(items) {
    if (!items) return 0;
    return Object.values(items).filter(item => item.quantity && !item.isCancelled).reduce((accu, item) => {
        return accu + item.price.taxes
    }, 0)
}

function calculateServices(items) {
    if (!items) return 0;
    return Object.values(items).filter(item => item.quantity && !item.isCancelled).reduce((accu, item) => {
        return accu + item.price.services
    }, 0)
}

export const calculateAncillaryTotals = (state) => {
    const concept = "ancillary";
    const total = calculateTotals(getConsumptionItems(state, concept));
    const subTotal = calculateSubTotals(getConsumptionItems(state, concept));
    const taxes = calculateTaxes(getConsumptionItems(state, concept));
    const services = calculateServices(getConsumptionItems(state, concept));
    const discount = consumptionDiscount(state, concept);
    return {
        "subTotal": subTotal,
        "discount": discount,
        "taxes": taxes,
        "services": services,
        "total": total - discount
    };
};

export const calculateAccommodation = (state) => {
    const countryCode = localStorage.getItem('country')
    const taxValue = localStorage.getItem('taxValue')
    const guestTaxDiscount = state.client.applyDiscount
    const paymentSecondaryCurrency = state.paymentTypeSelected.activedPaySecondCurrency

    const concept = "accommodation";
    const subTotal = calculateSubTotals(getConsumptionItems(state, concept));
    const total = calculateTotals(getConsumptionItems(state, concept));
    const taxes = calculateTaxes(getConsumptionItems(state, concept));
    const services = calculateServices(getConsumptionItems(state, concept));
    const discount = consumptionDiscount(state, concept)

    if(countryCode==='CL'){
        if(paymentSecondaryCurrency){
            console.log("Pago en dolares")
            if(!guestTaxDiscount){
                console.log("No Exonerado")
                return {
                    "subTotal": subTotal,
                    "discount": discount,
                    "taxes": subTotal * taxValue,
                    "services": services,
                    "total": subTotal + taxes + services - discount
                };
            }else{
                console.log("Exonerado")
                return {
                    "subTotal": subTotal,
                    "discount": discount,
                    "taxes": 0,
                    "services": services,
                    "total": subTotal + taxes + services - discount
                };
            }
        }else{
            console.log("Siempre con IVA")
            return {
                "subTotal": subTotal,
                "discount": discount,
                "taxes": subTotal * taxValue,
                "services": services,
                "total": subTotal + services - discount
            };
        }
    }
    return {
        "subTotal": subTotal,
        "discount": discount,
        "taxes": taxes,
        "services": services,
        "total": total - discount
    };
};

export const joinConsumption = (state) => {
    /* join all outlets from guest selected*/
    let concepts = {
        accommodation: {
            items: {},
        },
        ancillary: {
            items: {},
            itemPrices: {}
        }
    };

    let outletDescription = {};
    let processAncillary = false;
    const {applyDiscount} = state.client;
    const customers = guestsSelected(state);
    // console.log(customers);
    const isCompany = isCompanyPayment(state);
    customers.forEach(customerId => {
        const {[ACCO_CODE]: accommodation, ...ancillary} = guestOutletConsumption(state, customerId);
        // console.log(accommodation);
        if (accommodation) {
            const accommodationItem = [];
            accommodation.items.forEach(stayId => {
                const stay = getConsumption(state, stayId); //Esto trae la lista de Guest consumptions con el stayId que es una fecha
                // console.log(stay);
                // const calculateTaxes = isCompany || stay.applyTaxes;
                if (stay.productId) {
                    accommodationItem.push(
                        {
                            subtotal: stay.price.subtotal * stay.quantity,
                            taxes: stay.price.taxes * stay.quantity,
                            services: stay.price.services * stay.quantity,
                            total: stay.price.total * stay.quantity,
                        },
                    );
                }
                else {
                    let final_prices;
                    if (isCompany) {
                        final_prices = stay.price
                    } else {
                        if (stay.applyTaxes) {
                            final_prices = getPrice(stay.price, 1, accommodation.excises, 'acco', false, applyDiscount)
                        } else {
                            final_prices = stay.price
                        }
                    }
                    if (customers.length === 1 && concepts.accommodation.items.hasOwnProperty(stay.id)) {
                        // console.log("found existing attribute should add them")
                        const totalQuantity = parseInt(concepts.accommodation.items[stay.id]['maxQuantity']) + parseInt(stay.quantity)
                        const {subtotal, taxes, services, total} = concepts.accommodation.items[stay.id]['price']
                        // console.log(subtotal, taxes, services, total)
                        const sumOfPrices = {
                            "subtotal": subtotal + stay.price.subtotal,
                            "taxes": taxes + stay.price.taxes,
                            "services": services + stay.price.services,
                            "total": total + stay.price.total,
                        }
                        concepts.accommodation.items[stay.id] = {
                            ...stay,
                            maxQuantity: totalQuantity,
                            price: sumOfPrices,
                        }
                    } else {
                        concepts.accommodation.items[stay.id] = {
                            ...stay,
                            maxQuantity: stay.quantity,
                            price: final_prices,
                        }
                    }
                }
            });
            if (accommodationItem.length !== 0) {
                const outletCode = ACCO_CODE;
                outletDescription[outletCode] = 'Accomodation';
                concepts.ancillary.itemPrices[outletCode] = accommodationItem;
                processAncillary = true;
            }
        }
        if (ancillary) {
            Object.values(ancillary).forEach(outlet => {
                const outletCode = outlet.code;
                const items = outlet.items
                    .map(itemId => getConsumption(state, itemId))
                    .filter(item => !item.isCancelled);

                const item_prices = items.map(item => {
                    const calculateTaxes = isCompany || item.applyTaxes;
                    const excises = item.productExcises || outlet.excises;
                    return calculateTaxes ?
                        getPrice(item.price, item.quantity, excises, outletCode, item.applyTaxDiscount, applyDiscount)
                        :
                        {
                            subtotal: item.price.subtotal * item.quantity,
                            taxes: item.price.taxes * item.quantity,
                            services: item.price.services * item.quantity,
                            total: item.price.total * item.quantity,
                        }
                });
                if (!concepts.ancillary.itemPrices.hasOwnProperty(outletCode)) {
                    outletDescription[outletCode] = outlet.description;
                    concepts.ancillary.itemPrices[outletCode] = item_prices;
                    processAncillary = true;

                } else {
                    concepts.ancillary.itemPrices[outletCode] = [...concepts.ancillary.itemPrices[outletCode], ...item_prices]
                }
            });
        }
    });

    if (processAncillary) {
        Object.entries(concepts.ancillary.itemPrices).forEach(entry => {
            const [outletCode, prices] = entry;
            concepts.ancillary.items[outletCode] = {
                ...compactAncillary(prices),
                description: outletDescription[outletCode],
                code: outletCode
            }
        });
    }
    return concepts
};

function compactAncillary(prices) {
    const amount_detail = prices.reduce((detail, price) => {
        detail.total += price.total;
        detail.subtotal += price.subtotal;
        detail.taxes += price.taxes;
        detail.services += price.services;
        return detail

    }, {total: 0, subtotal: 0, taxes: 0, services: 0});

    return {
        price: amount_detail,
        selected: true,
        quantity: 1,
        maxQuantity: 1
    }
}

function getPrice(prices, quantity, excises, outlet = "acco", applyTaxDiscount = false, applyDiscount) {
    let {total, taxes, services, subtotal} = prices;
    if (outlet === "acco") {
        // const selectedtaxes = applyTaxDiscount && applyDiscount ? 0 : excises.taxes;
        taxes = subtotal * excises.taxesInclude;
        total = subtotal + taxes + services;
    } else {
        let tax = excises.taxesInclude + excises.taxesNonInclude
        let serv = excises.chargesInclude + excises.chargesNonInclude

        if (applyDiscount && applyTaxDiscount) {
            tax = 0
        }
        subtotal = (total / (1 + tax + serv)) * quantity;
        taxes = subtotal * tax * quantity;
        services = subtotal * serv * quantity;
        total = (subtotal + tax + services) * quantity
    }
    return {total, taxes, services, subtotal}
}


export function getConsumptionDetail(state) {
    const customers = guestsSelected(state);
    const consumptions = customers.reduce((acc, customerId) => {
        const {[ACCO_CODE]: accommodation, ...ancillary} = guestOutletConsumption(state, customerId);
        const acco = [];
        if (accommodation) {
            Object.values(accommodation.items).forEach(item => {
                if (String(item).length < 10) {
                    acco.push(item);
                }
            });
        }
        if (acco.length !== 0) {
            const outletACCO = accommodation;
            outletACCO['items'] = acco;
            ancillary['ACCO'] = outletACCO;
        }
        if (ancillary) {
            Object.values(ancillary).forEach(outlet => {

                const items = outlet.items.map(itemId => getConsumption(state, itemId));
                acc[outlet.code] = acc[outlet.code] ? acc[outlet.code].concat(items) : [].concat(items)
            })
        }
        return acc
    }, {});
    return consumptions
}