import axios from 'axios';
import {
    REFRESH_TOKEN_URL
} from './apiUrlsConstants';

import {postToAPI} from "../../common.utils";
import {API_SUPPORT_URL} from "../rumi/apiUrlsConstants";

/* REFACTORING AUTHENTICATION AND USER LOADING*/

export const baseHeaders = {
    'Accept': 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
};

export const getAuthBearerHeaders = (access) => {
    const bearerHeaders = {...baseHeaders}; //a copy
    bearerHeaders['Authorization'] = `Bearer ${access}`;
    return bearerHeaders
};

export const getRefreshAndAccessFromLocalStorage = () => {
    // console.log('getting access and token');
    const refreshToken = localStorage.getItem('refreshToken');
    const accessToken = localStorage.getItem('accessToken');
    // console.log(refreshToken, accessToken);
    return {refreshToken, accessToken}
};

export function getRequestInfo(url) {
    const subdomain = localStorage.getItem('subdomain');
    const host = process.env.REACT_APP_API_SERVER;
    const protocol =process.env.REACT_APP_PROTOCOL;

    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
    };
    const apiUrl = `${protocol}://${subdomain}.${host}/${url}`;
    return {
        apiUrl,
        headers
    }
}

export function makeAuthUrl(url) {
    let subdomain = window.location.hostname
    subdomain = subdomain.split('.');
    for (let i = 0 ; i < subdomain.length ; i++) {
        const domain = subdomain[i]
        if (domain != 'www'){
            subdomain = domain;
            break;
        }
    }
    const host = process.env.REACT_APP_API_SERVER;
    const protocol =process.env.REACT_APP_PROTOCOL;
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
    };
    const apiUrl = `${protocol}://${subdomain}.${host}/${url}`;
    return {
        apiUrl,
        headers
    }
}

export const verifySession = (refresh) => {
    const data = {
        refresh:refresh
    };
    return postToAPI(REFRESH_TOKEN_URL, data, baseHeaders)
};
/* REFACTORING AUTHENTICATION AND USER LOADING*/

export function setBearerToken(token) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}

export function deleteBearerToken() {
    delete axios.defaults.headers.common['Authorization'];
}

export function verifyRefreshToken(){
    const token = localStorage.getItem('JWT-access')
    const tokenData = {
        "token":token
    }
    const {apiUrl, headers} = getRequestInfo(REFRESH_TOKEN_URL)
    return axios.post(apiUrl, tokenData, {headers: headers})
        .then(
            (response) => {
                const successCode = response.status
                return { 'successCode':successCode}
            },
            (error) => {
                const errorCode = error.response.status
                return { 'errorCode':errorCode}
            }
        );
}

export function setAuthorizationToken(access) {
    if (access) {
        setBearerToken(access)
    } else {
        deleteBearerToken()
    }
}

export function baseGetAPIRequest(url, params={}) {
    const token = localStorage.getItem('accessToken');
    const {apiUrl, headers} = getRequestInfo(url);
    headers['Authorization'] = `Bearer ${token}`;
    return axios.get(apiUrl, {headers: headers, params: params})
}

export function basePostAPIRequest(url, data,responseType=null) {
    const token = localStorage.getItem('accessToken')
    const {apiUrl, headers} = getRequestInfo(url)
    headers['Authorization'] = `Bearer ${token}`;
    if(responseType){
        return axios.post(apiUrl, data, {headers: headers,responseType:responseType})
    }
    return axios.post(apiUrl, data, {headers: headers})
}

export function basePutAPIRequest(url, data) {
    const token = localStorage.getItem('accessToken')
    const {apiUrl, headers} = getRequestInfo(url)
    headers['Authorization'] = `Bearer ${token}`;
    return axios.put(apiUrl, data, {headers: headers})
}

export function baseDeleteAPIRequest(url) {
    const token = localStorage.getItem('accessToken')
    const {apiUrl, headers} = getRequestInfo(url)
    headers['Authorization'] = `Bearer ${token}`;
    return axios.delete(apiUrl, {headers: headers})
}

export function baseSupportGetList() {
    const url = API_SUPPORT_URL;
    const host = process.env.REACT_APP_SUPPORT_URL;
    const protocol =process.env.REACT_APP_PROTOCOL;
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
    };
    const apiUrl = `${protocol}://${host}/${url}`;
    return axios.get(apiUrl, {headers: headers})
}

export const cleanLocalStorage = () => {
    localStorage.clear();
};